<script lang="ts">
  export let lite = false;

  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
</script>

<button class:lite on:click={() => dispatch('click')}>
  <slot />
</button>

<style>
  button {
    font-size: var(--hey-font-size);
    font-family: var(--hey-font-family);
    background-color: var(--hey-accent-color);
    color: white;
    border: 1px solid var(--hey-accent-color);
    padding: 0.85em 1em;
    border-radius: .5em;
    cursor: pointer;
  }
  button:hover {
    transform: translateY(-1px);
    transition: transform .2s;
  }
  button.lite {
    color: var(--hey-accent-color);
    background-color: white;
  }
</style>
