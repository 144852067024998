<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#8a8a8a" viewBox="0 0 256 256"
  ><rect width="256" height="256" fill="none" /><line
    x1="200"
    y1="56"
    x2="56"
    y2="200"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="16"
  /><line
    x1="200"
    y1="200"
    x2="56"
    y2="56"
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="16"
  /></svg
>
