<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#8a8a8a" viewBox="0 0 256 256">
  <rect width="256" height="256" fill="none" />
  <polyline
    points="96 48 176 128 96 208"
    fill="none"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="24"
  />
</svg>
