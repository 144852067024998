<script lang="ts">
  import type { Config } from './types';
  import CookiesSettings from './CookiesSettings.svelte';
  import CookiesWelcome from './CookiesWelcome.svelte';
  import { getConfirmed, setConfirmed, enableScripts, sendConsentModeUpdate } from './utils';

  export let config: Config;
  export let key: string;
  export let show = true;

  let showSettings = false;

  const allCategories = config.categories
    .map(({ name }) => name)
    .filter((name) => name !== 'essential');

  let choices = [];

  function onShowSettings() {
    show = true;
    showSettings = true;
    choices = getConfirmed(key) ?? [];
  }
  (window as any).heyflowShowCookieSettings = onShowSettings;

  function onShowWelcome() {
    show = true;
    showSettings = false;
  }

  function onConfirmChoices(choices: string[]) {
    choices.sort();
    setConfirmed(key, choices);
    sendConsentModeUpdate(choices);
    enableScripts(choices);
    show = false;
  }



  function onAllowAll() {
    onConfirmChoices(allCategories);
  }

  function onRejectAll() {
    onConfirmChoices([]);
  }
</script>

<div class="heyflow-cookie-consent">
  {#if show}
    {#if !showSettings}
      <CookiesWelcome {config} {onShowSettings} {onAllowAll} {onRejectAll} />
    {:else}
      <CookiesSettings
        {config}
        {choices}
        {onShowWelcome}
        {onAllowAll}
        {onRejectAll}
        {onConfirmChoices}
      />
    {/if}
  {:else if !config.hideCookieButton}
    <div class="shield" on:click={onShowSettings}>
      <div class="shield-icon">
        <img src="https://cookie-consent.heyflow.cloud/cookie-icon.svg" alt="Cookie Icon" />
      </div>
    </div>
  {/if}
</div>

<style>
  .heyflow-cookie-consent {
    font-family: var(--hey-font-family);
    font-size: var(--hey-font-size);
    z-index: var(--hey-z-index);
    text-align: left;
    position: fixed;
    color: black;
  }

  .shield {
    position: fixed;
    bottom: 15px;
    left: 15px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow:
      2px 2px 10px 0 rgba(0, 0, 0, 0.15),
      0 0 2px -1px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.1s;
    background-color: white;
  }

  .shield-icon {
    transform: scale(0.7);
  }

  .shield:hover {
    transform: scale(1.05);
    box-shadow:
      4px 4px 15px 0 rgba(0, 0, 0, 0.2),
      0 0 2px -1px rgba(0, 0, 0, 0.1);
  }
</style>
