var _a;
import App from './App.svelte';
import { enableScripts, getConfirmed, sendConsentModeUpdate } from './utils';
const CONFIG_VAR = 'HEYFLOW_COOKIE_CONSENT_CONFIG';
function applyCssVarDefault(div, varName, defaultValue) {
    div.style.setProperty(varName, getComputedStyle(div).getPropertyValue(varName).trim() || defaultValue);
}
if (!window[CONFIG_VAR]) {
    console.error(`${CONFIG_VAR} not defined`);
}
else {
    const config = window[CONFIG_VAR];
    const key = `heyflow-cookie-consent/${(_a = config.domain) !== null && _a !== void 0 ? _a : location.host}`;
    const div = document.getElementById('heyflow-cookie-consent');
    applyCssVarDefault(div, '--hey-accent-color', '#3f51ff');
    applyCssVarDefault(div, '--hey-font-family', 'Helvetica, arial, sans-serif');
    applyCssVarDefault(div, '--hey-font-size', '14px');
    applyCssVarDefault(div, '--hey-z-index', '1001');
    applyCssVarDefault(div, '--hey-backdrop-opacity', '0.7');
    const choices = getConfirmed(key);
    new App({
        target: div,
        props: {
            config,
            key,
            show: choices === null,
        },
    });
    if (choices !== null) {
        sendConsentModeUpdate(choices);
        enableScripts(choices);
    }
}
