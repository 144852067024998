export function getConfirmed(key) {
    const value = localStorage.getItem(key);
    if (value === null)
        return null;
    if (value === '')
        return [];
    return value.split(',');
}
export function setConfirmed(key, choices) {
    localStorage.setItem(key, choices.join(','));
}
let lastEnabledScripts = null;
export function enableScripts(choices) {
    const newEnabledScripts = choices.join(',');
    if (lastEnabledScripts === null) {
        lastEnabledScripts = newEnabledScripts;
        const scripts = document.querySelectorAll('script[data-cookieconsent]');
        for (const script of scripts) {
            const attribute = script.getAttribute('data-cookieconsent');
            if (choices.includes(attribute)) {
                const clone = document.createElement('script');
                clone.textContent = script.textContent;
                script.parentElement.insertBefore(clone, script);
                script.remove();
            }
        }
    }
    else if (lastEnabledScripts !== newEnabledScripts) {
        location.reload();
    }
}
const language = navigator.language.split('-')[0];
export function i18n(text) {
    var _a;
    return (_a = text[language]) !== null && _a !== void 0 ? _a : text['en'];
}
export function sendConsentModeUpdate(choices) {
    const marketingEnabled = choices.includes('marketing');
    const analyticsEnabled = choices.includes('analytics');
    if (window.gtag)
        window.gtag('consent', 'update', {
            ad_storage: marketingEnabled ? 'granted' : 'denied',
            ad_user_data: marketingEnabled ? 'granted' : 'denied',
            ad_personalization: marketingEnabled ? 'granted' : 'denied',
            analytics_storage: analyticsEnabled ? 'granted' : 'denied',
        });
}
