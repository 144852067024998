<script lang="ts">
  import type { Config } from './types';
  import Popup from './Popup.svelte';
  import Button from './Button.svelte';
  import { i18n } from './utils';

  export let onShowSettings: () => void;
  export let onAllowAll: () => void;
  export let onRejectAll: () => void;

  export let config: Config;
</script>

<Popup {config}>
  <div class="headline1">{i18n(config.title)}</div>
  <div class="headline2">{i18n(config.welcomeHeadline)}</div>
  <div class="text">{i18n(config.welcomeText)}</div>
  <div class="buttons">
    <Button lite on:click={onShowSettings}>
      {i18n(config.buttons.settings)}
    </Button>
    <div class="buttons-shortcuts">
      {#if !config.hideRejectAll}
        <Button on:click={onRejectAll}>
          {i18n(config.buttons.rejectAll)}
        </Button>
      {/if}
      <Button on:click={onAllowAll}>
        {i18n(config.buttons.acceptAll)}
      </Button>
    </div>
  </div>
</Popup>

<style>
  .headline1 {
    font-size: 2em;
    font-weight: 600;
  }

  .headline2 {
    font-size: 1.3em;
    font-weight: 600;
    margin: 20px 0;
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 10px;
  }

  .buttons-shortcuts {
    display: flex;
    column-gap: 10px;
  }
</style>
