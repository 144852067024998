<script type="ts">
  export let checked: boolean;
  export let onChange: (checked: boolean) => void;
</script>

<label>
  <input type="checkbox" bind:checked on:change={() => onChange(checked)} />
  <div class="wrapper">
    <div class="indicator" />
  </div>
</label>

<style>
  input {
    display: none;
  }

  .wrapper {
    width: 48px;
    height: 24px;
    border-radius: 24px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.125);
    cursor: pointer;
    box-sizing: content-box;
    transition: background-color .2s linear;
  }

  .indicator {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(15%, -50%);
    background-color: rgba(0, 0, 0, 0.25);
    transition: all .2s linear;
  }

  input:checked ~ .wrapper {
    background-color: var(--hey-accent-color);
  }

  input:checked ~ .wrapper .indicator {
    transform: translate(150%, -50%);
    background-color: white;
  }
</style>
